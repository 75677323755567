import * as THREE from "three";
import WebGL from "three/examples/jsm/capabilities/WebGL.js";
gsap.registerPlugin(ScrollTrigger);

const scene = new THREE.Scene();
scene.background = new THREE.Color(0x252a36);
const camera = new THREE.PerspectiveCamera(
  75,
  window.innerWidth / window.innerHeight,
  0.1,
  1000
);

const renderer = new THREE.WebGLRenderer({ antialias: true });
renderer.setSize(window.innerWidth, window.innerHeight);
document.body.appendChild(renderer.domElement);

const geometry = new THREE.IcosahedronGeometry(6, 2);
const material = new THREE.MeshBasicMaterial({
  color: 0xffffff,
  wireframe: true,
});

//morado
const geometry2 = new THREE.IcosahedronGeometry(6, 2);
const material2 = new THREE.MeshBasicMaterial({
  color: 0xffffff,
  wireframe: true,
});

//verde
const geometry3 = new THREE.IcosahedronGeometry(6, 2);
const material3 = new THREE.MeshBasicMaterial({
  color: 0xffffff,
  wireframe: true,
});

const mesh = new THREE.Mesh(geometry, material);
mesh.position.z = -4;
mesh.position.x = 5;
mesh.position.y = 3;
mesh.rotation.x = Math.PI * 0.3;

const mesh2 = new THREE.Mesh(geometry2, material2);
mesh2.position.z = 12;
mesh2.position.x = -2;
mesh2.position.y = 4;
mesh2.rotation.x = Math.PI * 0.3;

const mesh3 = new THREE.Mesh(geometry3, material3);
mesh3.position.z = 15;
mesh3.position.x = 20;
mesh3.position.y = 0;
mesh3.rotation.x = Math.PI * 0.3;

scene.add(mesh, mesh2, mesh3);

/*
const mesh2 = new THREE.Mesh( geometry, material );
    mesh2.position.z = 4;
    mesh2.position.x = -3;
    mesh2.position.y = -15;
    mesh2.rotation.x = Math.PI * 0.3;


scene.add( mesh2 );
*/

window.addEventListener("resize", onWindowResize, false);
function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
  render();
}

/*
const textureLoader = new THREE.TextureLoader
const starTexture = textureLoader.load("1numerofondonegro.png")

const starVertices = []
for (let i = 0; i < 1000; i++){
    const x = (Math.random() - 0.5) * 100;
    const y = (Math.random() - 0.5) * 100;
    const z = -Math.random() - 0.5 * 100;
    starVertices.push(x, y, z);
}

starGeometry.setAttribute('position', new THREE.Float32BufferAttribute(starVertices, 3))

const stars = new THREE.Points(starGeometry, starMaterial, starTexture)
scene.add(stars)
*/

/*
//FONDO DE UNOS

const particlesGeometry = new THREE.BufferGeometry();
const particlesCount = 5000;

const vertices = new Float32Array(particlesCount);
for (let i = 0; i < 1000; i++) {
    vertices[i] = (Math.random() -0.6) * 70;
    const x = (Math.random() +1) * 1000
    const y = (Math.random() -1) * 1000
    const z = 20
}

particlesGeometry.setAttribute(
    "position",
    new THREE.BufferAttribute(vertices, 3)
)
*/
const getRandomParticelPos = (particleCount) => {
  const arr = new Float32Array(particleCount * 700);
  for (let i = 0; i < particleCount; i++) {
    arr[i] = (Math.random() - 0.6) * 70;
    const x = (Math.random() + 1) * 1000;
    const y = (Math.random() - 1) * 1000;
    const z = 0;
  }
  return arr;
};

const geometrys = [new THREE.BufferGeometry(), new THREE.BufferGeometry()];

geometrys[0].setAttribute(
  "position",
  new THREE.BufferAttribute(getRandomParticelPos(1000), 3)
);
geometrys[1].setAttribute(
  "position",
  new THREE.BufferAttribute(getRandomParticelPos(1000), 3)
);

const loader = new THREE.TextureLoader();

// material
const materials = [
  new THREE.PointsMaterial({
    size: 0.25,
    map: loader.load("/images/uno.png"),
    transparent: true,
    // color: "#ff0000"
  }),
  new THREE.PointsMaterial({
    size: 0.25,
    map: loader.load("/images/cero.png"),
    transparent: true,
    // color: "#0000ff"
  }),
];

const starsT1 = new THREE.Points(geometrys[0], materials[0]);
const starsT2 = new THREE.Points(geometrys[1], materials[1]);
scene.add(starsT1);
scene.add(starsT2);

/*
//FONDO DE UNOS

const particlesGeometry = new THREE.BufferGeometry();
const particlesCount = 5000;

const vertices = new Float32Array(particlesCount);
for (let i = 0; i < 1000; i++) {
    vertices[i] = (Math.random() -0.6) * 70;
    const x = (Math.random() +1) * 1000
    const y = (Math.random() -1) * 1000
    const z = 20
}

particlesGeometry.setAttribute(
    "position",
    new THREE.BufferAttribute(vertices, 3)
)

//const loader = new THREE.TextureLoader();

const material1 = new THREE.MeshBasicMaterial({
    map: loader.load('uno.png'),
  });

const stars = new THREE.Points(particlesGeometry, material1);

//FONDO DE CEROS
const particlesGeometry1 = new THREE.BufferGeometry();
const particlesCount1 = 5000;

const vertices1 = new Float32Array(particlesCount1);
for (let e = 0; e < 1000; e++) {
    vertices1[e] = (Math.random() -0.6) * 70;
    //const x = (Math.random() +1) * 1000
    //const y = (Math.random() -1) * 1000
    //const z = 20
}

particlesGeometry1.setAttribute(
    "position",
    new THREE.BufferAttribute(vertices1, 4)
)

const loader2 = new THREE.TextureLoader();

const material0 = new THREE.MeshBasicMaterial({
    map: loader2.load('uno.png'),
  });


const stars0 = new THREE.Points(particlesGeometry1, material0);
scene.add(stars, stars0)


/*
const starGeometry = new THREE.BufferGeometry()
const starMaterial = new THREE.PointsMaterial({
    color: 0xFFFFFF
})

const starVertices = []
    for (let i = 0; i < 10000; i++) {
        const x = (Math.random() - 0.5) * 5000
        const y = (Math.random() - 0.5) * 5000
        const z = -Math.random() * 2000
        starVertices.push(x, y, z)
    }

console.log(starVertices)
starGeometry.setAttribute('position', 
    new THREE.Float32BufferAttribute(
        starVertices, 3)
    )

const stars = new THREE.Points(starGeometry, starMaterial)
scene.add(stars)
*/

camera.position.z = 0;
camera.position.y = 0;
camera.position.x = 0;

const loadingManager = new THREE.LoadingManager();

//loadingManager.onStart = function(url, item, total) {
//    console.log(`Started Loading: ${url}`);
//}

const progressBar = document.getElementById("progress-bar");

loadingManager.onProgress = function (url, loaded, total) {
  progressBar.value = (loaded / total) * 100;
};

const progressBarContainer = document.querySelector("progress-bar-container");

loadingManager.onLoad = function () {
  progressBarContainer.style.display = "none";
};

loadingManager.onError = function (url) {
  console.log(`Got a problem loading: ${url}`);
};

function scalePercent(start, end) {
  return (scrollPercent - start) / (end - start);
}

function moveCamera() {
  const t = document.body.getBoundingClientRect().top;
  mesh.rotation.x += 0.003;
  mesh2.rotation.x += 0.003;
  mesh3.rotation.x += 0.003;

  camera.rotation.x = t * 0.0018;
  camera.rotation.y = t * 0.00175;
  camera.position.x = t * -0.004;
  camera.rotation.z = t * -0.0004;
  camera.position.z = t * -0.002;
  /*if (camera.position.z >= 8){
        camera.position.z = t * 0.01
    } else {
        camera.rotation.x = t * 0.0018;
    camera.rotation.y = t * 0.00175;
    camera.position.x = t * -0.004;
    camera.rotation.z = t * -0.0004;
    camera.position.z = t * -0.002;
    }
*/

  //camera.rotation.x = t * 0.01;
  //camera.rotation.y = t * 0.01;
  //camera.rotation.z = t * 0.01;

  //camera.position.z = t * -0.01;

  /*
    camera.rotation.y = t * +0.003;
    camera.position.z = t * +0.003;
    if (camera.rotation.y >= -3){
        camera.rotation.y = t * 0.003
    } else {
        camera.position.z = t * -0.004
        camera.rotation.y = t * 0.003
        camera.position.x = t * -10
        if (camera.rotation.y >= -5){
            //camera.rotation.y = t * 0.009
            //camera.rotation.x = t * -0.0001
            camera.rotation.z = t * -0.0009
            camera.rotation.y = t * 0.005
            camera.position.z = t * -0.009
            if(camera.position.z >= -1){
                camera.position.z = t * -0.0001
            }
        }
   

    }
        */
  //camera.position.y = t *
  //camera.rotation.x = t * 1
  //if(camera.rotation.y = 0.0001){
  //  camera.rotation.y = t * +0.01
  //}
}

//const axesHelper = new THREE.AxesHelper( 5 );
//scene.add( axesHelper );

document.body.onscroll = moveCamera;

//ANIMACIÓN
function animate() {
  requestAnimationFrame(animate);
  renderer.render(scene, camera);
  mesh.rotation.y += 0.0001;
  if (mesh.rotation.y >= 0.000000001) {
    mesh.rotation.y += 0;
  }
  if (camera.position.z >= 1) {
    mesh.rotation.y += 0.003;
  }
  mesh2.rotation.y += 0.0001;
  if (mesh2.rotation.y >= 0.000000001) {
    mesh2.rotation.y += 0;
  }
  if (camera.position.z >= 1) {
    mesh2.rotation.y += 0.003;
  }
  mesh3.rotation.y += 0.0001;
  if (mesh3.rotation.y >= 0.000000001) {
    mesh3.rotation.y += 0;
  }
  if (camera.position.z >= 1) {
    mesh3.rotation.y += 0.003;
  }
}

//WEBGL FUNCIONA/WARNING
if (WebGL.isWebGLAvailable()) {
  // Initiate function or other initializations here
  animate();
} else {
  const warning = WebGL.getWebGLErrorMessage();
  document.getElementById("container").appendChild(warning);
}
